import React from "react";
import '../css/index.css';

class Footer extends React.Component {
  render() {
    return (
      <>
        <footer>
         <marquee><h1>Tacit and Explicit Knowledge Technologies</h1></marquee>
       
        </footer>
      </>
    );
  }
}

export default Footer;
